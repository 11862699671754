<template>
    <div>
        <Header></Header>
        <div class="content">
            <h3>{{title}}</h3>
            <div class="text_content" v-html="html"></div>
        </div>
        <gap height="50"></gap>
        <Homefooter></Homefooter>
    </div>
</template>

<script>
import { sourc, sourc2,selectByMark} from "@/apiUrl/index.js";
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["userInfo", "config","login_show","token","videoclass","histryList","hotSearch"]),
    },
    data() {
        return {
            sourc,
            sourc2,
            title:'',
            type:'',
            html:'',
        };
    },
    watch:{
        
    },
    created() {
        this.title = this.$route.query.title || '';
        this.type = this.$route.query.type || '';
        this.getdata();
    },
    methods: {
        getdata(){
            selectByMark({mark:this.type}).then((res)=>{
                if(res.code==200){
                    this.html = res.data.content || '';
                }else{
                    this.$msg(res.message,3)
                }
            })
        }
    },
};
</script>

<style lang="less" scoped>
    .content{
        padding: 30px 0;
    }
    h3{
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 22px;
        font-weight: 100;
    }
    .text_content{
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 2;
        color: #333333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #cccccc;
        border-radius: 4px;
        overflow: auto;
        font-family: monospace,monospace;
    }
</style>
